
import './App.css';
import './assets/css/bootstrap.css';
import './assets/css/fonts.css';
import './assets/css/style.css';
import Header from './components/Header';
import Navbar from './components/Navbar';
import { BrowserRouter, Routes, Router, Route } from 'react-router-dom';
import Home from './pages/Home';
import Pricing from './pages/Pricing';
import FAQPage from './pages/FAQ';
import Market from './pages/Market';
import Blogs from './components/blogs';
import About from './pages/About';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';



function App() {
  return (
    <>
      <Header />
      <Navbar />
      {/* <About /> */}
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/market" element={<Market />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
      </BrowserRouter>
    </>

  );
}

export default App;
