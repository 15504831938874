export default function AboutBCL() {
  return (
    <>

      <section className="section section-lg section-one-screen" id="buddhalab">
        <div className="container">
          <div className="row row-30">
            <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5">
              {/* Post modern*/}
              <article className="post-modern">
                <h4 className="post-modern-title">
                  About Buddha Cognitive Lab Private Limited.
                </h4>
                <div className="post-modern-time h5">
                  <time dateTime={2023}>Jan 10, 2023</time>
                </div>
                <div className="post-modern-figure">
                  <div className="image-hover">
                    <img
                      className="post-modern-image"
                      src="images/image-14-660x409.jpg"
                      alt=""
                      width={660}
                      height={409}
                      loading="lazy"
                    />
                  </div>
                </div>
              </article>
            </div>
            <div className="col-lg-6 col-xl-6 offset-xl-1 col-xxl-4 offset-xxl-2">
              <p className="bigger">
                Welcome to Buddha Cognitive Lab, where innovation meets purpose. At Buddha Cognitive Lab, we're not just developers, engineers, or marketers — we're dreamers and doers on a mission to redefine what's possible with artificial intelligence (AI). Our journey began with a simple yet profound vision: to harness the transformative power of AI to enhance lives and empower businesses.
              </p>
              <p className="bigger"> Our Mission </p>

              At Buddha Cognitive Lab, our mission is clear: to enhance the quality of life for every individual
              through the application of advanced technology. We believe in the potential of AI to solve complex problems,
              create new opportunities, and unlock human potential. Our products and solutions are not just tools;
              they're pathways to cognitive enlightenment, empowering individuals and businesses to achieve more with less.

              <p className="bigger">Our Vision </p>

              <p>We envision a future where AI is not just a tool but a trusted partner in progress.
                Our vision is to be at the forefront of the AI revolution, developing best-in-class technology
                that sets new standards in innovation, efficiency, and accessibility.
                We strive for a world where AI is a catalyst for positive change, contributing to a smarter,
                more connected society. </p>

              <p className="bigger">
                Our Values
              </p>
              <ul className="list list-marked list-secondary list-2-column block block-lg">
                <li className="list-item">Innovation: We push the boundaries of AI to create novel solutions that inspire and transform.</li>
                <li className="list-item">Equality: We believe in providing equal opportunities and benefits of AI to all, fostering inclusivity and diversity.</li>
                <li className="list-item">Integrity: We uphold the highest standards of ethics and transparency in our work, ensuring trust and reliability.</li>
                <li className="list-item">Empowerment: We empower individuals and businesses through technology, enabling them to enhance their lives and work.</li>
              </ul>
              <div className="offset-xs">
                <p className="bigger">
                  Join us on our journey to redefine the future with AI.
                  Together, we can unlock new possibilities and create a world where
                  technology and humanity thrive hand in hand.
                </p>
              </div>
              <div className="offset-xs group-20 d-flex flex-wrap align-items-center">
                <div>Share This Post</div>
                <div>
                  {/* List social*/}
                  <ul className="list list-social">
                    <li className="list-social-item">
                      <a
                        className="icon icon-circle icon-md icon-style-1 mdi-facebook"
                        href="#"
                      />
                    </li>
                    <li className="list-social-item">
                      <a
                        className="icon icon-circle icon-md icon-style-1 mdi-twitter"
                        href="#"
                      />
                    </li>
                    <li className="list-social-item">
                      <a
                        className="icon icon-circle icon-md icon-style-1 mdi-youtube-play"
                        href="#"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}