export default function Who() {
    return (
        <>
            <section className="section section-lg section-one-screen" id="who">
                <div className="container">
                    <h2 className="text-center">Who</h2>
                    <div className="row row-offset-lg justify-content-center">
                        <div className="col-xl-11 col-xxl-8">
                            <div
                                className="owl-carousel owl-style-1"
                                data-owl='{"dots":true,"responsive":{"768":{"items":2}}}'
                            >
                                {/* Quote modern*/}
                                <article className="quote quote-modern">
                                    <div className="media media-xs flex-column flex-xs-row-reverse">
                                        <div className="media-left">
                                            <div className="quote-author-figure">
                                                <div className="quote-author-image-decor" />
                                                <img
                                                    className="quote-author-image rounded-circle"
                                                    src="images/persons/person-02-64x64.jpg"
                                                    alt=""
                                                    width={64}
                                                    height={64}
                                                    loading="lazy"
                                                />
                                            </div>
                                        </div>
                                        <div className="media-body">
                                            <div className="quote-author-name h5">Sam Jones</div>
                                            <div className="quote-text">
                                                Sam is a seasoned marketing strategist with a proven track record of
                                                developing and implementing successful marketing campaigns for global brands.
                                                He excels in identifying market trends and crafting innovative strategies to drive business growth.
                                                <br />
                                                Capabilities: Developing comprehensive marketing strategies,
                                                conducting market analysis, optimizing campaign performance
                                            </div>
                                            <div className="quote-author-company">Designation: Marketing Strategy Expert</div>
                                        </div>
                                    </div>
                                </article>
                                {/* Quote modern*/}
                                <article className="quote quote-modern">
                                    <div className="media media-xs flex-column flex-xs-row-reverse">
                                        <div className="media-left">
                                            <div className="quote-author-figure">
                                                <div className="quote-author-image-decor" />
                                                <img
                                                    className="quote-author-image rounded-circle"
                                                    src="images/persons/person-01-64x64.jpg"
                                                    alt=""
                                                    width={64}
                                                    height={64}
                                                    loading="lazy"
                                                />
                                            </div>
                                        </div>
                                        <div className="media-body">
                                            <div className="quote-author-name h5">Eva Smith</div>
                                            <div className="quote-text">
                                                Eva is a skilled recruiter with extensive experience in talent acquisition and candidate sourcing.
                                                She has a keen eye for identifying top talent and matching candidates with the right opportunities.
                                                <br />
                                                Capabilities: Conducting candidate searches, screening resumes,
                                                coordinating interviews, managing recruitment pipelines
                                            </div>
                                            <div className="quote-author-company">Recruitment Expert</div>
                                        </div>
                                    </div>
                                </article>
                                {/* Quote modern*/}
                                <article className="quote quote-modern">
                                    <div className="media media-xs flex-column flex-xs-row-reverse">
                                        <div className="media-left">
                                            <div className="quote-author-figure">
                                                <div className="quote-author-image-decor" />
                                                <img
                                                    className="quote-author-image rounded-circle"
                                                    src="images/persons/person-03-64x64.jpg"
                                                    alt=""
                                                    width={64}
                                                    height={64}
                                                    loading="lazy"
                                                />
                                            </div>
                                        </div>
                                        <div className="media-body">
                                            <div className="quote-author-name h5">Deric Jonson</div>
                                            <div className="quote-text">
                                                Deric is a seasoned product manager with a proven track record of successfully launching and managing software products from conception to market.
                                                He excels in translating customer needs into product requirements and driving cross-functional teams to deliver results.
                                                <br />
                                                Capabilities: Defining product vision and strategy, prioritizing features, managing product lifecycle
                                            </div>
                                            <div className="quote-author-company">Product Manager</div>
                                        </div>
                                    </div>
                                </article>
                                {/* Quote modern*/}
                                <article className="quote quote-modern">
                                    <div className="media media-xs flex-column flex-xs-row-reverse">
                                        <div className="media-left">
                                            <div className="quote-author-figure">
                                                <div className="quote-author-image-decor" />
                                                <img
                                                    className="quote-author-image rounded-circle"
                                                    src="images/persons/person-04-64x64.jpg"
                                                    alt=""
                                                    width={64}
                                                    height={64}
                                                    loading="lazy"
                                                />
                                            </div>
                                        </div>
                                        <div className="media-body">
                                            <div className="quote-author-name h5">Rajesh Sharma</div>
                                            <div className="quote-text">
                                                Rajesh is a highly skilled solution architect with extensive experience in designing and implementing scalable and secure IT solutions for enterprise clients.
                                                He has a deep understanding of cloud technologies and system integration.
                                                <br />
                                                Capabilities: Designing and implementing complex IT solutions,
                                                architecting cloud-based infrastructure, providing technical guidance and support
                                            </div>
                                            <div className="quote-author-company">Solution Architect</div>
                                        </div>
                                    </div>
                                </article>
                                {/* Quote modern
                                <article className="quote quote-modern">
                                    <div className="media media-xs flex-column flex-xs-row-reverse">
                                        <div className="media-left">
                                            <div className="quote-author-figure">
                                                <div className="quote-author-image-decor" />
                                                <img
                                                    className="quote-author-image rounded-circle"
                                                    src="images/persons/person-01-64x64.jpg"
                                                    alt=""
                                                    width={64}
                                                    height={64}
                                                    loading="lazy"
                                                />
                                            </div>
                                        </div>
                                        <div className="media-body">
                                            <div className="quote-author-name h5">John Doue</div>
                                            <div className="quote-text">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                                do eiusmod tempor incididunt ut labore et dolore. Lorem ipsum
                                                dolor sit amet, consectetur adipiscing.
                                            </div>
                                            <div className="quote-author-company">SEO "Company"</div>
                                        </div>
                                    </div>
                                </article>
                                
                                <article className="quote quote-modern">
                                    <div className="media media-xs flex-column flex-xs-row-reverse">
                                        <div className="media-left">
                                            <div className="quote-author-figure">
                                                <div className="quote-author-image-decor" />
                                                <img
                                                    className="quote-author-image rounded-circle"
                                                    src="images/persons/person-02-64x64.jpg"
                                                    alt=""
                                                    width={64}
                                                    height={64}
                                                    loading="lazy"
                                                />
                                            </div>
                                        </div>
                                        <div className="media-body">
                                            <div className="quote-author-name h5">John Doue</div>
                                            <div className="quote-text">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                                do eiusmod tempor incididunt ut labore et dolore. Lorem ipsum
                                                dolor sit amet, consectetur adipiscing.
                                            </div>
                                            <div className="quote-author-company">SEO "Company"</div>
                                        </div>
                                    </div>
    </article> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}