import oldpricing from "../assets/images/pricing.png"
import Login from "./Login";

export default function Pricing() {
  return (
    <>
      {/* Pricing plans*/}
      <section className="section section-lg" id="pricing">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-10">
              <div className="group-20 d-sm-flex align-items-sm-end justify-content-sm-between text-center">
                <h2>Pricing Plan</h2>
                
                <div>
                  {/* Multi switch*/}
                  <div
                    className="switch-text group-25 d-inline-flex align-items-center justify-content-center"
                    id="multiswitch"
                  >
                    <div>
                      <span className="switch-text-left biggest">Annual</span>
                      <span className="switch-text-right biggest ms-2 border-start ps-2">
                        Monthly
                      </span>
                    </div>
                    <div
                      className="switch-toggle-modern"
                      data-multi-switch='{"targets":"#multiswitch, #pricing-group","state":true}'
                    >
                      <div className="guide" />
                      <div className="slider">
                        <div className="slider-dot" />
                        <div className="slider-dot" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             
              <div className="pricing-group offset-xs" id="pricing-group">
                {/* Owl Carousel*/}
                <div
                  className="owl-carousel owl-style-1 owl-item-end"
                  data-owl='{"loop":false,"autoplay":false,"mouseDrag":false,"dots":true,"responsive":{"768":{"items":2},"992":{"items":3},"1600":{"items":3,"margin":100}}}'
                >
                   <img
                     
                     src={oldpricing}
                     width={1000}
                     
                   />
                  <article
                    className="pricing pricing-primary block block-sm block-center"
                    data-animate='{"class":"fadeInUp"}'
                  >
                    <div className="pricing-body">
                      <div className="pricing-title biggest">Alpha Users</div>
                      <div className="pricing-details">
                        <div className="pricing-price h2">
                          <span className="pricing-month">$10</span>
                          <span className="pricing-year">$100</span>
                        </div>
                        <div className="pricing-period">
                          Per
                          <div className="h5 fw-normal">
                            <span className="pricing-month">Month</span>
                            <span className="pricing-year">Year</span>
                          </div>
                        </div>
                      </div>
                      <div className="pricing-divider">
                        <hr className="divider" />
                      </div>
                      <div className="pricing-list">
                        <ul className="list list-marked-check d-inline-block text-start">
                          <li className="list-item">5 GB Cloud Storage</li>
                          <li className="list-item">100 Paññā Credits</li>
                          <li className="list-item">Basic AI models access</li>
                          <li className="list-item">Maximum of 10 Projects</li>
                          <li className="list-item">Maximum of 10 VCEs</li>
                          <li className="list-item disabled">24/7 Support</li>
                          <li className="list-item disabled">Team collaboration</li>
                          <li className="list-item disabled">Access to all VCE  and Beta VCE</li>
                          <li className="list-item disabled">Customized Workflows</li>
                        </ul>
                      </div>
                      <div className="pricing-btn">
                        <Login />
                      </div>
                    </div>
                  </article>
                 
                  {/*   
                  Old Pricing
                  <article
                    className="pricing pricing-primary block block-sm block-center"
                    data-animate='{"class":"fadeInUp"}'
                  >
                    <div className="pricing-body">
                      <div className="pricing-title biggest">Solopreneur</div>
                      <div className="pricing-details">
                        <div className="pricing-price h2">
                          <span className="pricing-month">$20</span>
                          <span className="pricing-year">$200</span>
                        </div>
                        <div className="pricing-period">
                          Per
                          <div className="h5 fw-normal">
                            <span className="pricing-month">Month</span>
                            <span className="pricing-year">Year</span>
                          </div>
                        </div>
                      </div>
                      <div className="pricing-divider">
                        <hr className="divider" />
                      </div>
                      <div className="pricing-list">
                        <ul className="list list-marked-check d-inline-block text-start">
                          <li className="list-item">5 GB Cloud Storage</li>
                          <li className="list-item">100 Paññā Credits</li>
                          <li className="list-item">Basic AI models access</li>
                          <li className="list-item">Maximum of 10 Projects</li>
                          <li className="list-item">Maximum of 10 VCEs</li>
                          <li className="list-item disabled">24/7 Support</li>
                          <li className="list-item disabled">Team collaboration</li>
                          <li className="list-item disabled">Access to all VCE  and Beta VCE</li>
                          <li className="list-item disabled">Customized Workflows</li>
                        </ul>
                      </div>
                      <div className="pricing-btn">
                        <button
                          className="btn btn-sm"
                          data-modal-trigger='{"target":"#modal"}'
                        >
                          Select Plan
                        </button>
                      </div>
                    </div>
                  </article>
               
                  <article
                    className="pricing pricing-secondary block block-sm block-center"
                    data-animate='{"class":"fadeInUp"}'
                  >
                    <div className="pricing-badge">Popular</div>
                    <div className="pricing-body">
                      <div className="pricing-title biggest">Startup</div>
                      <div className="pricing-details">
                        <div className="pricing-price h2">
                          <span className="pricing-month">$40</span>
                          <span className="pricing-year">$400</span>
                        </div>
                        <div className="pricing-period">
                          Per
                          <div className="h5 fw-normal">
                            <span className="pricing-month">Month</span>
                            <span className="pricing-year">Year</span>
                          </div>
                        </div>
                      </div>
                      <div className="pricing-divider">
                        <hr className="divider" />
                      </div>
                      <div className="pricing-list">
                        <ul className="list list-marked-check d-inline-block text-start">
                        <li className="list-item">10 GB Cloud Storage</li>
                          <li className="list-item">500 Paññā Credits</li>
                          <li className="list-item">Advance AI models access</li>
                          <li className="list-item">Maximum of 100 Projects</li>
                          <li className="list-item">Maximum of 100 VCEs</li>
                          <li className="list-item">Team collaboration</li>
                          <li className="list-item">24/7 Support</li>
                          <li className="list-item disabled">Access to all VCE  and Beta VCE</li>
                          <li className="list-item disabled">Customized Workflows</li>
                        </ul>
                      </div>
                      <div className="pricing-btn">
                        <button
                          className="btn btn-sm"
                          data-modal-trigger='{"target":"#modal"}'
                        >
                          Select Plan
                        </button>
                      </div>
                    </div>
                  </article>
                 
                  <article
                    className="pricing pricing-success block block-sm block-center"
                    data-animate='{"class":"fadeInUp"}'
                  >
                    <div className="pricing-body">
                      <div className="pricing-title biggest">Enterprize</div>
                      <div className="pricing-details">
                        <div className="pricing-price h2">
                          <span className="pricing-month">$100</span>
                          <span className="pricing-year">$1000</span>
                        </div>
                        <div className="pricing-period">
                          Per
                          <div className="h5 fw-normal">
                            <span className="pricing-month">Month</span>
                            <span className="pricing-year">Year</span>
                          </div>
                        </div>
                      </div>
                      <div className="pricing-divider">
                        <hr className="divider" />
                      </div>
                      <div className="pricing-list">
                        <ul className="list list-marked-check d-inline-block text-start">
                        <li className="list-item">100 GB Cloud Storage</li>
                          <li className="list-item">1000 Paññā Credits</li>
                          <li className="list-item">Advance AI models access</li>
                          <li className="list-item">Unlimited Projects</li>
                          <li className="list-item">Unlimited VCEs</li>
                          <li className="list-item">Team collaboration</li>
                          <li className="list-item">24/7 Support</li>
                          <li className="list-item">Access to all VCE and Beta VCE</li>
                          <li className="list-item">Customized Workflows</li>
                        </ul>
                      </div>
                      <div className="pricing-btn">
                        <button
                          className="btn btn-sm"
                          data-modal-trigger='{"target":"#modal"}'
                        >
                          Select Plan
                        </button>
                      </div>
                    </div>
                  </article> */}


                </div>
               
              </div>
              
            </div>
           
          </div>
        </div>
      </section>
      {/* Modal*/}
      <div className="modal fade" id="modal" tabIndex={-1} role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body text-center">
              <h4>
                Get an offer for
                <br />a basic plan
              </h4>
              {/*RD Mailform*/}
              <form
                className="rd-mailform"
                data-form-output="modal-form-output-global"
                data-form-type="contact"
                method="post"
                action="bat/rd-mailform.php"
              >
                <div className="form-group">
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    placeholder="Your name *"
                    data-constraints="@Required"
                  />
                </div>
                <div className="form-group">
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    placeholder="Your E-mail *"
                    data-constraints="@Email @Required"
                  />
                </div>
                <div className="offset-xs">
                  <button className="btn btn-block" type="submit">
                    Send Request
                  </button>
                </div>
              </form>
            </div>
            <button
              className="close"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
      </div>
      <div
        className="form-output snackbar snackbar-secondary"
        id="modal-form-output-global"
      />

    </>
  );
}