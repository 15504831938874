import HireNow from "./HireNow";

export default function Why() {
    return(
        <>
                <section className="section" id="why">
        <div className="container">
          <div
            className="swiper-container swiper-pagination-creative"
            data-swiper='{"scrollbar":{"el":""},"loop":false,"autoplay":{"delay":5000,"disableOnInteraction":false},"breakpoints":{"320":{"autoplay":false},"1200":{"autoplay":{"delay":5000,"disableOnInteraction":false}}}}'
          >
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="row align-items-center justify-content-lg-center flex-md-row-reverse">
                  <div className="col-md-5 col-lg-4 offset-xxl-1 text-md-center">
                    <img
                      className="mt-md-3 ms-xxl-1 swiper-pagination-creative-image"
                      src="images/image-02-534x572.png"
                      alt=""
                      width={534}
                      height={572}
                      loading="lazy"
                    />
                  </div>
                  <div className="col-md-7 col-lg-6 col-xxl-5 offset-xl-1">
                    <h1>Efficiency</h1>
                    <div className="offset-xxs">
                      <p className="biggest">
                      Streamline your workflow and accelerate project timelines with the help of our VCRs.
                      </p>
                    </div>
                    <div className="offset-sm">
                      <div className="group-30 offset-sm">
                        
                      <HireNow />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="row align-items-center justify-content-lg-center flex-md-row-reverse">
                  <div className="col-md-5 col-lg-4 offset-xxl-1 text-md-center">
                    <img
                      className="mt-md-3 ms-xxl-1 swiper-pagination-creative-image"
                      src="images/image-03-434x609.png"
                      alt=""
                      width={434}
                      height={609}
                      loading="lazy"
                    />
                  </div>
                  <div className="col-md-7 col-lg-6 col-xxl-5 offset-xl-1">
                    <h1>Quality</h1>
                    <div className="offset-xxs">
                      <p className="biggest">
                      Rest assured that your tasks are in capable hands, with VCRs delivering top-quality outcomes every time.
                      </p>
                    </div>
                    <div className="offset-sm">
                      <div className="group-30 offset-sm">
                      <HireNow />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="row align-items-center justify-content-lg-center flex-md-row-reverse">
                  <div className="col-md-5 col-lg-4 offset-xxl-1 text-md-center">
                    <img
                      className="mt-md-3 ms-xxl-1 swiper-pagination-creative-image"
                      src="images/image-01-647x551.png"
                      alt=""
                      width={647}
                      height={551}
                      loading="lazy"
                    />
                  </div>
                  <div className="col-md-7 col-lg-6 col-xxl-5 offset-xl-1">
                    <h1>Flexibility</h1>
                    <div className="offset-xxs">
                      <p className="biggest">
                      Scale your workforce up or down as needed, with the ability to access additional resources on demand.
                      </p>
                    </div>
                    <div className="offset-sm">
                      <div className="group-30 offset-sm">
                        
                      <HireNow />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="row align-items-center justify-content-lg-center flex-md-row-reverse">
                  <div className="col-md-5 col-lg-4 offset-xxl-1 text-md-center">
                    <img
                      className="mt-md-3 ms-xxl-1 swiper-pagination-creative-image"
                      src="images/image-03-434x609.png"
                      alt=""
                      width={434}
                      height={609}
                      loading="lazy"
                    />
                  </div>
                  <div className="col-md-7 col-lg-6 col-xxl-5 offset-xl-1">
                    <h1>Innovation</h1>
                    <div className="offset-xxs">
                      <p className="biggest">
                      Stay ahead of the curve with access to cutting-edge AI technologies and expertise across various industries
                      </p>
                    </div>
                    <div className="offset-sm">
                      <div className="group-30 offset-sm">
                      <HireNow />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="row align-items-center justify-content-lg-center flex-md-row-reverse">
                  <div className="col-md-5 col-lg-4 offset-xxl-1 text-md-center">
                    <img
                      className="mt-md-3 ms-xxl-1 swiper-pagination-creative-image"
                      src="images/image-01-647x551.png"
                      alt=""
                      width={647}
                      height={551}
                      loading="lazy"
                    />
                  </div>
                  <div className="col-md-7 col-lg-6 col-xxl-5 offset-xl-1">
                    <h1>Customer Satisfaction</h1>
                    <div className="offset-xxs">
                      <p className="biggest">
                      Join the ranks of satisfied customers who have experienced firsthand the transformative power of HIRE-BUDDHA.
                      </p>
                    </div>
                    <div className="offset-sm">
                      <div className="group-30 offset-sm">
                        
                      <HireNow />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-pagination" data-pagination-progress="" />
            <div className="swiper-counter">
              <span className="swiper-counter-count">01</span>
              <span className="swiper-counter-divider" />
              <span className="swiper-counter-total">05</span>
            </div>
            <ul className="swiper-soc">
              <li className="list-social-item">
                <a className="icon icon-circle icon-style-1 mdi-facebook" href="#" />
              </li>
              <li className="list-social-item">
                <a className="icon icon-circle icon-style-1 mdi-twitter" href="#" />
              </li>
            </ul>
          </div>
        </div>
        <div
          className="form-output snackbar snackbar-secondary"
          id="form-output-subscribe"
        />
      </section>
        </>
    );
}