
export default function Blogs() {
    return(
        <>
<section className="section section-lg pb-0">
<div className="container">
  <div className="row row-10 align-items-lg-end justify-content-lg-between">
    <div className="col-lg-8 col-xl-7 col-xxl-5">
      <h2>What's new?</h2>
      <p className="biggest">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod tempor incididunt ut labore et dolore magna aliqua.
      </p>
    </div>
    <div className="col-lg-auto">
      <ul className="nav nav-line slick-filter bigger justify-content-start">
        <li className="nav-item slick-filter-item" role="presentation">
          <button
            className="nav-link slick-filter-link active"
            data-filter="*"
            data-bs-toggle="tab"
          >
            See All
          </button>
        </li>
        <li className="nav-item slick-filter-item" role="presentation">
          <button
            className="nav-link slick-filter-link"
            data-filter="Popular"
            data-bs-toggle="tab"
          >
            Popular
          </button>
        </li>
        <li className="nav-item slick-filter-item" role="presentation">
          <button
            className="nav-link slick-filter-link"
            data-filter="Newest"
            data-bs-toggle="tab"
          >
            Newest
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>
<div
  className="slick-slider slick-slider-1"
  data-slick='{"infinite":false,"arrows":true,"slidesToShow":3,"autoplay":false,"responsive":[{"breakpoint":992,"settings":{"slidesToShow":2}},{"breakpoint":576,"settings":{"slidesToShow":1}}]}'
>
  <div data-category="Newest">
    {/* Post*/}
    <article className="post image-hover">
      <img
        className="post-image"
        src="images/image-11-640x464.jpg"
        alt=""
        width={640}
        height={464}
        loading="lazy"
      />
      <div className="post-caption">
        <div className="post-time h4">
          <time dateTime={2023}>Jan 10, 2023</time>
        </div>
        <h3 className="post-title">
          <a href="single-post.html">
            We Have a Lot of Useful Solutions for You
          </a>
        </h3>
      </div>
      <div className="post-dummy" />
    </article>
  </div>
  <div data-category="Popular">
    {/* Post*/}
    <article className="post image-hover">
      <img
        className="post-image"
        src="images/image-12-640x464.jpg"
        alt=""
        width={640}
        height={464}
        loading="lazy"
      />
      <div className="post-caption">
        <div className="post-time h4">
          <time dateTime={2023}>Jan 10, 2023</time>
        </div>
        <h3 className="post-title">
          <a href="single-post.html">
            We Have a Lot of Useful Solutions for You
          </a>
        </h3>
      </div>
      <div className="post-dummy" />
    </article>
  </div>
  <div data-category="Newest">
    {/* Post*/}
    <article className="post image-hover">
      <img
        className="post-image"
        src="images/image-13-640x464.jpg"
        alt=""
        width={640}
        height={464}
        loading="lazy"
      />
      <div className="post-caption">
        <div className="post-time h4">
          <time dateTime={2023}>Jan 10, 2023</time>
        </div>
        <h3 className="post-title">
          <a href="single-post.html">
            We Have a Lot of Useful Solutions for You
          </a>
        </h3>
      </div>
      <div className="post-dummy" />
    </article>
  </div>
  <div data-category="Popular">
    {/* Post*/}
    <article className="post image-hover">
      <img
        className="post-image"
        src="images/image-15-640x464.jpg"
        alt=""
        width={640}
        height={464}
        loading="lazy"
      />
      <div className="post-caption">
        <div className="post-time h4">
          <time dateTime={2023}>Jan 10, 2023</time>
        </div>
        <h3 className="post-title">
          <a href="single-post.html">
            We Have a Lot of Useful Solutions for You
          </a>
        </h3>
      </div>
      <div className="post-dummy" />
    </article>
  </div>
  <div data-category="Newest">
    {/* Post*/}
    <article className="post image-hover">
      <img
        className="post-image"
        src="images/image-16-640x464.jpg"
        alt=""
        width={640}
        height={464}
        loading="lazy"
      />
      <div className="post-caption">
        <div className="post-time h4">
          <time dateTime={2023}>Jan 10, 2023</time>
        </div>
        <h3 className="post-title">
          <a href="single-post.html">
            We Have a Lot of Useful Solutions for You
          </a>
        </h3>
      </div>
      <div className="post-dummy" />
    </article>
  </div>
  <div data-category="Popular">
    {/* Post*/}
    <article className="post image-hover">
      <img
        className="post-image"
        src="images/image-11-640x464.jpg"
        alt=""
        width={640}
        height={464}
        loading="lazy"
      />
      <div className="post-caption">
        <div className="post-time h4">
          <time dateTime={2023}>Jan 10, 2023</time>
        </div>
        <h3 className="post-title">
          <a href="single-post.html">
            We Have a Lot of Useful Solutions for You
          </a>
        </h3>
      </div>
      <div className="post-dummy" />
    </article>
  </div>
  <div data-category="Newest">
    {/* Post*/}
    <article className="post image-hover">
      <img
        className="post-image"
        src="images/image-12-640x464.jpg"
        alt=""
        width={640}
        height={464}
        loading="lazy"
      />
      <div className="post-caption">
        <div className="post-time h4">
          <time dateTime={2023}>Jan 10, 2023</time>
        </div>
        <h3 className="post-title">
          <a href="single-post.html">
            We Have a Lot of Useful Solutions for You
          </a>
        </h3>
      </div>
      <div className="post-dummy" />
    </article>
  </div>
  <div data-category="Popular">
    {/* Post*/}
    <article className="post image-hover">
      <img
        className="post-image"
        src="images/image-13-640x464.jpg"
        alt=""
        width={640}
        height={464}
        loading="lazy"
      />
      <div className="post-caption">
        <div className="post-time h4">
          <time dateTime={2023}>Jan 10, 2023</time>
        </div>
        <h3 className="post-title">
          <a href="single-post.html">
            We Have a Lot of Useful Solutions for You
          </a>
        </h3>
      </div>
      <div className="post-dummy" />
    </article>
  </div>
  <div data-category="Newest">
    {/* Post*/}
    <article className="post image-hover">
      <img
        className="post-image"
        src="images/image-15-640x464.jpg"
        alt=""
        width={640}
        height={464}
        loading="lazy"
      />
      <div className="post-caption">
        <div className="post-time h4">
          <time dateTime={2023}>Jan 10, 2023</time>
        </div>
        <h3 className="post-title">
          <a href="single-post.html">
            We Have a Lot of Useful Solutions for You
          </a>
        </h3>
      </div>
      <div className="post-dummy" />
    </article>
  </div>
  <div data-category="Newest">
    {/* Post*/}
    <article className="post image-hover">
      <img
        className="post-image"
        src="images/image-16-640x464.jpg"
        alt=""
        width={640}
        height={464}
        loading="lazy"
      />
      <div className="post-caption">
        <div className="post-time h4">
          <time dateTime={2023}>Jan 10, 2023</time>
        </div>
        <h3 className="post-title">
          <a href="single-post.html">
            We Have a Lot of Useful Solutions for You
          </a>
        </h3>
      </div>
      <div className="post-dummy" />
    </article>
  </div>
</div>
</section>

</>
    );
}