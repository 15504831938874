import BUDDHALOGO from '../assets/images/HIRE BUDDHA.png'
import HireNow from './HireNow';
export default function Navbar() {
    return (
        <>
            <header className="section rd-navbar-wrap">
                <nav className="rd-navbar context rd-navbar-original rd-navbar-fullwidth">
                    <div className="navbar-container">
                        <div className="navbar-cell">
                            <div className="navbar-panel">
                                <button
                                    className="navbar-switch"
                                    data-multi-switch='{"targets":".rd-navbar","scope":".rd-navbar","isolate":"[data-multi-switch]"}'
                                />
                                <div className="navbar-logo">
                                    <a className="navbar-logo-link" href="/">
                                        <img
                                            className="navbar-logo-default"
                                            src={BUDDHALOGO}
                                            alt="Hire Buddha"
                                            width={100}
                                            height={100}
                                            loading="lazy"
                                        />
                                        <img
                                            className="navbar-logo-inverse"
                                            src={BUDDHALOGO}
                                            alt="Hire Buddha"
                                            width={100}
                                            height={100}
                                            loading="lazy"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="navbar-spacer" />
                        <div className="navbar-cell navbar-sidebar">
                            <ul className="navbar-navigation rd-navbar-nav fullpage-navigation">
                                <li
                                    className="navbar-navigation-root-item active"
                                    data-menuanchor="home"
                                >
                                    <a className="navbar-navigation-root-link" href="/">
                                        Home
                                    </a>
                                    <ul className="navbar-navigation-dropdown rd-navbar-dropdown">
                                        <li className="navbar-navigation-back">
                                            <button className="navbar-navigation-back-btn">Back</button>
                                        </li>
                                        <li className="navbar-navigation-dropdown-item active">
                                            <a className="navbar-navigation-dropdown-link" href="/#what">
                                                What
                                            </a>
                                        </li>
                                        <li className="navbar-navigation-dropdown-item">
                                            <a className="navbar-navigation-dropdown-link" href="/#how">
                                                How
                                            </a>
                                        </li>
                                        <li className="navbar-navigation-dropdown-item">
                                            <a className="navbar-navigation-dropdown-link" href="/#why">
                                                Why
                                            </a>
                                        </li>
                                        <li className="navbar-navigation-dropdown-item">
                                            <a className="navbar-navigation-dropdown-link" href="/#who">
                                                Who
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li
                                    className="navbar-navigation-root-item"
                                    data-menuanchor="pricing"
                                >
                                    <a className="navbar-navigation-root-link" href="/pricing">
                                        Pricing
                                    </a>
                                </li>                    
                                <li className="navbar-navigation-root-item" data-menuanchor="">
                                    <a className="navbar-navigation-root-link" href="/faq">
                                        FAQ
                                    </a>

                                </li>
                                <li className="navbar-navigation-root-item" data-menuanchor="">
                                    <a className="navbar-navigation-root-link" href="/blogs">
                                        Blogs
                                    </a>

                                </li>
                                <li className="navbar-navigation-root-item" data-menuanchor="">
                                    <a className="navbar-navigation-root-link" href="/about">
                                        About
                                    </a>
                                    <ul className="navbar-navigation-dropdown rd-navbar-dropdown">
                                        <li className="navbar-navigation-back">
                                            <button className="navbar-navigation-back-btn">Back</button>
                                        </li>
                                        <li className="navbar-navigation-dropdown-item active">
                                            <a className="navbar-navigation-dropdown-link" href="/about/#hirebuddha">
                                                About HireBuddha
                                            </a>
                                        </li>
                                        <li className="navbar-navigation-dropdown-item">
                                            <a className="navbar-navigation-dropdown-link" href="/about/#buddhalab">
                                                About Buddha Cognitive Lab
                                            </a>
                                        </li>
                                      {/*   <li className="navbar-navigation-dropdown-item">
                                            <a className="navbar-navigation-dropdown-link" href="/about/#team">
                                                Team
                                            </a>
                                        </li>
                                        <li className="navbar-navigation-dropdown-item">
                                            <a className="navbar-navigation-dropdown-link" href="/about/#careers">
                                                Careers
                                            </a>
                                        </li> */}
                                        <li className="navbar-navigation-dropdown-item">
                                            <a className="navbar-navigation-dropdown-link" href="/about/#contacts">
                                                Contact Us
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="navbar-cell">
                            <div className="navbar-subpanel">
                                <div className="navbar-subpanel-item">
                                    <button
                                        className="navbar-button navbar-info-button mdi-dots-vertical"
                                        data-multi-switch='{"targets":".rd-navbar","scope":".rd-navbar","class":"navbar-info-active","isolate":"[data-multi-switch]"}'
                                    />
                                    <div className="navbar-info">
                                      <HireNow />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    );
}