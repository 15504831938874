

export default function Terms() {
    return(
        <>
          <div className="container">
  <h2 className="text-center">Privacy Policy</h2>
  <div className="row row-offset-sm row-30 justify-content-center accordion">
    <div className="col-lg-6">
      {/* Accordion*/}
      <article className="accordion-item active">
        <button className="accordion-head">
          <span className="accordion-title h5">General Information</span>
          <span className="accordion-icon">
            <span className="icon icon-circle icon-sm icon-style-2 mdi-plus" />
          </span>
        </button>
        <div className="accordion-body">
          <div className="accordion-text">
            Welcome to our Privacy Policy page! When you use our web site
            services, you trust us with your information. This Privacy Policy is
            meant to help you understand what data we collect, why we collect
            it, and what we do with it. When you share information with us, we
            can make our services even better for you. For instance, we can show
            you more relevant search results and ads, help you connect with
            people or to make sharing with others quicker and easier. As you use
            our services, we want you to be clear how we’re using information
            and the ways in which you can protect your privacy. This is
            important; we hope you will take time to read it carefully.
            Remember, you can find controls to manage your information and
            protect your privacy and security. We’ve tried to keep it as simple
            as possible.
          </div>
        </div>
      </article>
      {/* Accordion*/}
      <article className="accordion-item">
        <button className="accordion-head">
          <span className="accordion-title h5">
            Right to Access, Correct and Delete Data
          </span>
          <span className="accordion-icon">
            <span className="icon icon-circle icon-sm icon-style-2 mdi-plus" />
          </span>
        </button>
        <div className="accordion-body">
          <div className="accordion-text">
            Our customers have the right to access, correct and delete personal
            data relating to them, and to object to the processing of such data,
            by addressing a written request, at any time. The Company makes
            every effort to put in place suitable precautions to safeguard the
            security and privacy of personal data, and to prevent it from being
            altered, corrupted, destroyed or accessed by unauthorized third
            parties. However, the Company does not control each and every risk
            related to the use of the Internet, and therefore warns the Site
            users of the potential risks involved in the functioning and use of
            the Internet. The Site may include links to other web sites or other
            internet sources. As the Company cannot control these web sites and
            external sources, the Company cannot be held responsible for the
            provision or display of these web sites and external sources, and
            may not be held liable for the content, advertising, products,
            services or any other material available on or from these web sites
            or external sources.
          </div>
        </div>
      </article>
      {/* Accordion*/}
      <article className="accordion-item">
        <button className="accordion-head">
          <span className="accordion-title h5">
            Management of Personal Data
          </span>
          <span className="accordion-icon">
            <span className="icon icon-circle icon-sm icon-style-2 mdi-plus" />
          </span>
        </button>
        <div className="accordion-body">
          <div className="accordion-text">
            You can view or edit your personal data online for many of our
            services. You can also make choices about our collection and use of
            your data. How you can access or control your personal data will
            depend on which services you use. You can choose whether you wish to
            receive promotional communications from our web site by email, SMS,
            physical mail, and telephone. If you receive promotional email or
            SMS messages from us and would like to opt out, you can do so by
            following the directions in that message. You can also make choices
            about the receipt of promotional email, telephone calls, and postal
            mail by visiting and signing into Company Promotional Communications
            Manager, which allows you to update contact information, manage
            contact preferences, opt out of email subscriptions, and choose
            whether to share your contact information with our partners. These
            choices do not apply to mandatory service communications that are
            part of certain web site services.
          </div>
        </div>
      </article>
    </div>
  </div>
</div>

        </>
    );
}