export default function FAQ() {
    return (
        <>
            <section className="section section-lg section-one-screen" id="FAQ">
                <div className="container">
                    <h2 className="text-center">FAQ</h2>
                    <div className="row row-offset-sm row-30 accordion">
                        <div className="col-lg-6">
                            {/* Accordion*/}
                            <article className="accordion-item active">
                                <button className="accordion-head">
                                    <span className="accordion-title h5">
                                    What exactly are Virtual Cognitive Resources (VCRs)?
                                    </span>
                                    <span className="accordion-icon">
                                        <span className="icon icon-circle icon-sm icon-style-2 mdi-plus" />
                                    </span>
                                </button>
                                <div className="accordion-body">
                                    <div className="accordion-text">
                                    VCRs are AI-powered virtual assistants with specialized skills and expertise in various domains, 
                                    capable of performing tasks and interacting with users through natural speech and voice commands.
                                    </div>
                                </div>
                            </article>
                            {/* Accordion*/}
                            <article className="accordion-item">
                                <button className="accordion-head">
                                    <span className="accordion-title h5">
                                    How can HIRE-BUDDHA benefit my business?
                                    </span>
                                    <span className="accordion-icon">
                                        <span className="icon icon-circle icon-sm icon-style-2 mdi-plus" />
                                    </span>
                                </button>
                                <div className="accordion-body">
                                    <div className="accordion-text">
                                    HIRE-BUDDHA offers cost-effective access to highly skilled VCRs, 
                                    enabling you to streamline operations, increase productivity, 
                                    and scale your workforce on-demand, all while reducing overhead costs.
                                    </div>
                                </div>
                            </article>
                            {/* Accordion*/}
                            <article className="accordion-item">
                                <button className="accordion-head">
                                    <span className="accordion-title h5">
                                    What industries can benefit from HIRE-BUDDHA's services?
                                    </span>
                                    <span className="accordion-icon">
                                        <span className="icon icon-circle icon-sm icon-style-2 mdi-plus" />
                                    </span>
                                </button>
                                <div className="accordion-body">
                                    <div className="accordion-text">
                                    HIRE-BUDDHA caters to a wide range of industries, including technology, marketing, HR, finance, and more. 
                                    Our versatile VCRs can adapt to various business needs and requirements.
                                    </div>
                                </div>
                            </article>
                            {/* Accordion*/}
                            <article className="accordion-item">
                                <button className="accordion-head">
                                    <span className="accordion-title h5">
                                    Are the VCRs capable of handling sensitive or confidential information?
                                    </span>
                                    <span className="accordion-icon">
                                        <span className="icon icon-circle icon-sm icon-style-2 mdi-plus" />
                                    </span>
                                </button>
                                <div className="accordion-body">
                                    <div className="accordion-text">
                                    Yes, HIRE-BUDDHA prioritizes data security and confidentiality. 
                                    Our platform is equipped with advanced security measures, 
                                    including encryption and access controls, to safeguard your sensitive information.
                                    </div>
                                </div>
                            </article>
                            {/* Accordion*/}
                            <article className="accordion-item">
                                <button className="accordion-head">
                                    <span className="accordion-title h5">
                                    Can I customize the tasks and workflows assigned to VCRs?
                                    </span>
                                    <span className="accordion-icon">
                                        <span className="icon icon-circle icon-sm icon-style-2 mdi-plus" />
                                    </span>
                                </button>
                                <div className="accordion-body">
                                    <div className="accordion-text">
                                    Absolutely! HIRE-BUDDHA allows you to create custom task templates and workflows tailored to your specific requirements. 
                                    You have full control over the tasks assigned to VCRs and can adjust them as needed.
                                    </div>
                                </div>
                            </article>
                              {/* Accordion*/}
                              <article className="accordion-item">
                                <button className="accordion-head">
                                    <span className="accordion-title h5">
                                    How quickly can I expect tasks to be completed by VCRs?
                                    </span>
                                    <span className="accordion-icon">
                                        <span className="icon icon-circle icon-sm icon-style-2 mdi-plus" />
                                    </span>
                                </button>
                                <div className="accordion-body">
                                    <div className="accordion-text">
                                    Task completion times may vary depending on the complexity and scope of the task. 
                                    However, our VCRs work efficiently and diligently to ensure prompt delivery of high-quality results.
                                    </div>
                                </div>
                            </article>
                              {/* Accordion*/}
                              <article className="accordion-item">
                                <button className="accordion-head">
                                    <span className="accordion-title h5">
                                    Can I communicate with multiple VCRs simultaneously?
                                    </span>
                                    <span className="accordion-icon">
                                        <span className="icon icon-circle icon-sm icon-style-2 mdi-plus" />
                                    </span>
                                </button>
                                <div className="accordion-body">
                                    <div className="accordion-text">
                                    Yes, you can communicate with multiple VCRs simultaneously to delegate tasks, collaborate on projects, and manage workflows effectively. 
                                    Our platform supports seamless multitasking and team collaboration.
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div className="col-lg-6">
                            {/* Accordion*/}
                            <article className="accordion-item">
                                <button className="accordion-head">
                                    <span className="accordion-title h5">
                                    How does pricing work for HIRE-BUDDHA?
                                    </span>
                                    <span className="accordion-icon">
                                        <span className="icon icon-circle icon-sm icon-style-2 mdi-plus" />
                                    </span>
                                </button>
                                <div className="accordion-body">
                                    <div className="accordion-text">
                                    HIRE-BUDDHA operates on a pay-as-you-go model, where you're only charged for the AI compute utilized. 
                                    We offer flexible pricing options and transparent billing to ensure affordability and transparency.
                                    </div>
                                </div>
                            </article>
                            {/* Accordion*/}
                            <article className="accordion-item">
                                <button className="accordion-head">
                                    <span className="accordion-title h5">
                                    Can I try HIRE-BUDDHA before committing to a subscription?
                                    </span>
                                    <span className="accordion-icon">
                                        <span className="icon icon-circle icon-sm icon-style-2 mdi-plus" />
                                    </span>
                                </button>
                                <div className="accordion-body">
                                    <div className="accordion-text">
                                    Absolutely! We offer a free trial period for new users to experience the platform firsthand and explore its capabilities. 
                                    No credit card is required to sign up for the trial.
                                    </div>
                                </div>
                            </article>
                            {/* Accordion*/}
                            <article className="accordion-item">
                                <button className="accordion-head">
                                    <span className="accordion-title h5">
                                    How do I communicate with the VCRs on the HIRE-BUDDHA platform?
                                    </span>
                                    <span className="accordion-icon">
                                        <span className="icon icon-circle icon-sm icon-style-2 mdi-plus" />
                                    </span>
                                </button>
                                <div className="accordion-body">
                                    <div className="accordion-text">
                                    You can interact with VCRs through natural speech and voice commands, just like communicating with a human counterpart. 
                                    Our intuitive interface makes it easy to collaborate and delegate tasks effectively.
                                    </div>
                                </div>
                            </article>
                             {/* Accordion*/}
                             <article className="accordion-item">
                                <button className="accordion-head">
                                    <span className="accordion-title h5">
                                    What happens if I'm not satisfied with the outcome of a task performed by a VCR?
                                    </span>
                                    <span className="accordion-icon">
                                        <span className="icon icon-circle icon-sm icon-style-2 mdi-plus" />
                                    </span>
                                </button>
                                <div className="accordion-body">
                                    <div className="accordion-text">
                                    Customer satisfaction is our top priority. If you're not satisfied with the outcome of a task, you can provide feedback and request revisions. 
                                    Our VCRs will rework and are committed to delivering results that meet your expectations.
                                    </div>
                                </div>
                            </article>
                            {/* Accordion*/}
                            <article className="accordion-item">
                                <button className="accordion-head">
                                    <span className="accordion-title h5">
                                    Are there any limitations on the types of tasks VCRs can perform?
                                    </span>
                                    <span className="accordion-icon">
                                        <span className="icon icon-circle icon-sm icon-style-2 mdi-plus" />
                                    </span>
                                </button>
                                <div className="accordion-body">
                                    <div className="accordion-text">
                                    While our VCRs are capable of handling a wide range of tasks, there may be certain limitations based on complexity or specialized expertise required. 
                                    We continuously expand our VCR capabilities to meet evolving customer needs.
                                    </div>
                                </div>
                            </article>
                             {/* Accordion*/}
                             <article className="accordion-item">
                                <button className="accordion-head">
                                    <span className="accordion-title h5">
                                    Can I cancel my subscription to HIRE-BUDDHA at any time?
                                    </span>
                                    <span className="accordion-icon">
                                        <span className="icon icon-circle icon-sm icon-style-2 mdi-plus" />
                                    </span>
                                </button>
                                <div className="accordion-body">
                                    <div className="accordion-text">
                                    Yes, you can cancel your subscription to HIRE-BUDDHA at any time with no long-term commitments or penalties. 
                                    We believe in providing flexibility and freedom to our customers.
                                    </div>
                                </div>
                            </article>
                            {/* Accordion*/}
                            <article className="accordion-item">
                                <button className="accordion-head">
                                    <span className="accordion-title h5">
                                    How can I get started with HIRE-BUDDHA?
                                    </span>
                                    <span className="accordion-icon">
                                        <span className="icon icon-circle icon-sm icon-style-2 mdi-plus" />
                                    </span>
                                </button>
                                <div className="accordion-body">
                                    <div className="accordion-text">
                                    Getting started with HIRE-BUDDHA is easy! Simply sign up for a free trial, 
                                    explore the platform's features and capabilities, 
                                    and start delegating tasks to our virtual cognitive resources to experience the future of workforce management.
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}