import HireNow from "./HireNow";

export default function Contact() {
    return (
        <>
            <section className="section section-lg" id="contacts">
                <div className="container">
                    <div className="row row-30 align-items-xl-center justify-content-xxl-between">
                        <div className="col-lg-6">
                            <h2>Get in Touch</h2>
                            <p className="bigger">
                                Drop in your queries about the Hire Buddha, Buddha Cognitive Lab Private Limited or if you want to partner with us below:
                            </p>
                            <form
                                className="rd-mailform"
                                data-form-output="form-output-global"
                                data-form-type="contact"
                                method="post"
                                action="bat/rd-mailform.php"
                            >
                                <div className="row row-30">
                                    <div className="col-xs-6">
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="name"
                                                placeholder="Your name *"
                                                data-constraints="@Required"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xs-6">
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                type="email"
                                                name="email"
                                                placeholder="Your e-mail address *"
                                                data-constraints="@Email @Required"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <textarea
                                                className="form-control"
                                                name="question"
                                                placeholder="Your question"
                                                rows={5}
                                                data-constraints="@Required"
                                                defaultValue={""}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="offset-sm group-40 d-flex flex-wrap flex-xs-nowrap align-items-center">
                                    <button className="btn" type="submit">
                                        Subscribe
                                    </button>
                                    {/* Checkbox*/}
                                    
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-6 col-xxl-5">
                            <div className="box px-xl-3 px-xxl-4">
                                <div className="row row-30">
                                    <div className="col-md-6 col-lg-12 col-xl-6">
                                        <h4>Get The App:</h4>
                                        <div className="group-30 offset-sm">
                                            
                                            <HireNow />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-12 col-xl-6">
                                        <h4>Contacts:</h4>
                                        <table className="table table-sm table-responsive-xl table-no-bordered bigger">
                                            <tbody>
                                                <tr>
                                                    <td className="text-end text-600 ps-0 align-middle">
                                                        Ph.
                                                    </td>
                                                    <td className="biggest">
                                                        <a className="link-inherit" href="tel:#">
                                                            +91-8149-60-3400
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-end text-600 ps-0">Mail.</td>
                                                    <td>
                                                        <a className="link-inherit" href="mailto:#">
                                                            info@hirebuddha.in
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-end text-600 ps-0">Office.</td>
                                                    <td>
                                                        53, Gharkul, Indrayani Nagar, Beltarodi Road, Nagpur, Maharashtra, India, Pin - 440037.
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <hr className="divider" />
                                <div className="group-20 d-flex flex-wrap align-items-center justify-content-between">
                                    <div>
                                        {/* List social*/}
                                        <ul className="list list-social">
                                            <li className="list-social-item">
                                                <a
                                                    className="icon icon-circle icon-md icon-style-1 mdi-facebook"
                                                    href="#"
                                                />
                                            </li>
                                            <li className="list-social-item">
                                                <a
                                                    className="icon icon-circle icon-md icon-style-1 mdi-twitter"
                                                    href="#"
                                                />
                                            </li>
                                            <li className="list-social-item">
                                                <a
                                                    className="icon icon-circle icon-md icon-style-1 mdi-youtube-play"
                                                    href="#"
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <p className="rights bigger">
                                            <span>©&nbsp;</span>
                                            <span className="copyright-year" />
                                            <span>.&nbsp;</span>
                                            <span>Hire Buddha</span>
                                            <span>.&nbsp;</span>
                                            <a href="/privacy">Privacy Policy</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="form-output snackbar snackbar-secondary"
                    id="form-output-global"
                />
            </section>
        </>

    );
}