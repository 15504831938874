export default function AboutHB() {
    return (
        <>
            <section className="section section-lg section-one-screen" id="hirebuddha">
                <div className="container">

                    <h2 className="text-center">Hire Buddha</h2>
                    <div className="row row-offset-sm row-30 justify-content-center accordion">
                        <div className="col-lg-6">
                            {/* Accordion*/}
                            <article className="accordion-item active">
                                <button className="accordion-head">
                                    <span className="accordion-title h5">General Information</span>
                                    <span className="accordion-icon">
                                        <span className="icon icon-circle icon-sm icon-style-2 mdi-plus" />
                                    </span>
                                </button>
                                <div className="accordion-body">
                                    <div className="accordion-text">
                                        HIRE-BUDDHA revolutionizes workforce management with Virtual Cognitive Resources (VCRs),
                                        AI-powered virtual assistants that deliver top-quality outcomes at a fraction of the cost.
                                        With HIRE-BUDDHA, businesses gain access to a diverse pool of experts spanning HR, marketing, technology, and more.
                                        VCRs work 24/7, communicate naturally, and excel in tasks from recruitment to marketing campaigns.
                                        The platform offers seamless task delegation, flexible pricing, and a pay-as-you-go model, ensuring affordability and scalability.
                                        Say goodbye to traditional hiring headaches and hello to efficiency, innovation, and cost savings with HIRE-BUDDHA."
                                    </div>
                                </div>
                            </article>
                            {/* Accordion*/}
                            <article className="accordion-item">
                                <button className="accordion-head">
                                    <span className="accordion-title h5">
                                    Our Commitment
                                    </span>
                                    <span className="accordion-icon">
                                        <span className="icon icon-circle icon-sm icon-style-2 mdi-plus" />
                                    </span>
                                </button>
                                <div className="accordion-body">
                                    <div className="accordion-text">
                                    We are committed to responsible AI development. 
                                    From rigorous testing to transparency and adherence to privacy standards, 
                                    we prioritize the ethical implications of our work, ensuring that our technology 
                                    is used for the greater good. We believe in harnessing the power of AI to create a brighter, 
                                    more inclusive future for all.
                                    </div>
                                </div>
                            </article>
                            {/* Accordion*/}
                            <article className="accordion-item">
                                <button className="accordion-head">
                                    <span className="accordion-title h5">
                                        Parent Company
                                    </span>
                                    <span className="accordion-icon">
                                        <span className="icon icon-circle icon-sm icon-style-2 mdi-plus" />
                                    </span>
                                </button>
                                <div className="accordion-body">
                                    <div className="accordion-text">
                                    The parent company of HIRE BUDDHA is Buddha Cognitive Lab, 
                                    where we harness the power of generative AI to transform lives and businesses. 
                                    Our mission is to improve the quality of life for everyone through innovative 
                                    technology solutions. We're committed to developing best-in-class technology 
                                    with a focus on equal opportunity 
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}