import Pricing from "../components/Pricing";
import Hero from "../components/Hero";
import What from "../components/What";
import How from "../components/How";
import Why from "../components/Why";
import Who from "../components/Who";
import FAQ from "../components/FAQ";

export default function Home() {
  return (
    <>
      {/* Hero Section*/}
      <Hero />
      {/* What Section*/}
      <What />
      {/* How*/}
      <How />
      {/* Why*/}
      <Why />
      {/* Who*/}
      <Who />
      {/* Pricing */}
      <Pricing />
      
      {/* FAQ*/}
      <FAQ />
    </>

  );
}