import Contact from "../components/Contact";
import AboutBCL from "../components/aboutBuddhaCognitiveLab";
import AboutHB from "../components/aboutHireBuddha";

export default function About() {
  return (
    <>
      {/* About Hire Buddha*/}
      <AboutHB />
      {/* About Buddha Cognitive Lab*/}
      <AboutBCL />
      {/* Team*/}
      
      {/* Career*/}

      {/* Contact*/}
      <Contact />
    </>
  );
}