import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios'; // Import axios





export default function Login() {
  
  const handleLoginSuccess = async (tokenResponse) => {
   

    try {
      const res = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: {
              "Authorization": `Bearer ${tokenResponse.access_token}`
          }
      })

      console.log(res.data)
      const response = await axios.post('http://localhost:8000/auth/google',res.data);
      if (!response.status==200) {
        throw new Error(`Error: ${response.status}`); // Error handling
      }
      //const data = await response.json();
      console.log('Login success : ', response);
      // Handle navigation or state update after successful login here
      // navigate('/dashboard'); // Example navigation after login
    } catch (error) {
      console.error('Login failed:', error);
    }
  };
  
  const login = useGoogleLogin({
    onSuccess: handleLoginSuccess,
    onError: error => console.log(error),
  });

  
  

  return (
    <>
    <button
    className="btn btn-sm"
     onClick={() => login()}
  >
    Select Plan
  </button>
  </> 
  );

}