import hero1 from "../assets/images/HireBuddha.png"
import hero2 from "../assets/images/Meeting.png"
import hero3 from "../assets/images/Programming.png"
import hero4 from "../assets/images/Sarah.png"
import hero5 from "../assets/images/alex1.png"
import hero6 from "../assets/images/Programming.png"
import HireNow from "./HireNow"

export default function Hero() {
    return (
        <>
            <section className="section" id="home">
                <div
                    className="swiper-container swiper-pagination-minimal"
                    data-swiper='{"simulateTouch":false,"scrollbar":{"el":""},"autoplay":{"delay":12000,"disableOnInteraction":false},"breakpoints":{"32000":{"autoplay":false},"1200":{"autoplay":{"delay":12000,"disableOnInteraction":false}}}}'
                >
                    <div className="bg-layer d-flex align-items-end">
                        <svg
                            className="image-svg"
                            width={1920}
                            height={792}
                            viewBox="0 0 1920 792"
                            fill="none"
                        >
                            <path
                                opacity="0.03"
                                d="M1245 671.5H0V792H1920V0.5H1450C1392.01 0.5 1345 47.5101 1345 105.5V571.5C1345 626.728 1300.23 671.5 1245 671.5Z"
                                fill="white"
                            />
                            <circle cx={509} cy={121} r={18} fill="#45494B" />
                            <circle cx="367.5" cy="138.5" r="9.5" fill="#FF8C68" />
                            <circle cx="526.5" cy="431.5" r="6.5" fill="#78C5D6" />
                            <defs>
                            </defs>
                        </svg>
                    </div>
                    <div className="swiper-wrapper">
                        <div className="swiper-slide section-lg">
                            <div className="container">
                                <div className="words">
                                    <span>H</span>
                                    <span>I</span>
                                    <span>R</span>
                                    <span>E</span>
                                    <span>&nbsp; </span>
                                    <span>&nbsp; </span>
                                    <span>B</span>
                                    <span>U</span>
                                    <span>D</span>
                                    <span>D</span>
                                    <span>H</span>
                                    <span>A</span>
                                </div>
                                <div className="row align-items-center justify-content-lg-between flex-md-row-reverse">
                                    <div className="col-8 col-md-5 col-lg-7 text-end">
                                        <img className="animate slide delay-8"
                                            src={hero1}
                                            alt=""
                                            width={600}
                                            height={700}
                                            loading="lazy"
                                        />
                                    </div>
                                    <div className="col-md-7 col-lg-6 col-xxl-4">
                                        <h3>Revolutionizing Workforce with Virtual Cognitive Resources</h3>
                                        <div className="offset-xxs">
                                            <p className="bigger">
                                                Are you tired of lengthy recruitment processes, soaring costs, and the hassle of finding the right talent for your business?
                                                Say goodbye to traditional hiring methods and welcome the future of workforce management with Hire Buddha.
                                            </p>
                                        </div>
                                        <div className="offset-sm">
                                        <HireNow />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide section-lg">
                            <div className="container">
                                <div className="words">
                                    <span>H</span>
                                    <span>I</span>
                                    <span>R</span>
                                    <span>E</span>
                                    <span>&nbsp; </span>
                                    <span>&nbsp; </span>
                                    <span>B</span>
                                    <span>U</span>
                                    <span>D</span>
                                    <span>D</span>
                                    <span>H</span>
                                    <span>A</span>
                                </div>
                                <div className="row align-items-center justify-content-lg-between flex-md-row-reverse">
                                    <div className="col-7 col-md-5 col-xxl-7 text-center">
                                        <img
                                            className="animate slide delay-8"
                                            src={hero2}
                                            alt=""
                                            width={600}
                                            height={700}
                                            loading="lazy"
                                        />
                                    </div>
                                    <div className="col-md-7 col-lg-5 col-xxl-4">
                                        <h3>What is Hire Buddha?</h3>
                                        <div className="offset-xxs">
                                            <p className="bigger">
                                                Hire Buddha is not just another recruitment platform – it's a game-changer.
                                                Our revolutionary product enables you to hire Virtual Cognitive Resources (VCRs), expert professionals who work tirelessly 24/7 at a fraction of the cost.
                                                Each VCR is backed by a powerful AI model, providing you with unparalleled efficiency and productivity.
                                            </p>
                                        </div>
                                        <div className="offset-sm">
                                        <HireNow />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide section-lg">
                            <div className="container">
                                <div className="words">
                                    <span>H</span>
                                    <span>I</span>
                                    <span>R</span>
                                    <span>E</span>
                                    <span>&nbsp; </span>
                                    <span>&nbsp; </span>
                                    <span>B</span>
                                    <span>U</span>
                                    <span>D</span>
                                    <span>D</span>
                                    <span>H</span>
                                    <span>A</span>
                                </div>
                                <div className="row align-items-center justify-content-md-between flex-md-row-reverse">
                                    <div className="col-5 col-md-4 col-lg-4 col-xxl-7 text-center">
                                        <img
                                            className="animate slide delay-8"
                                            src={hero3}
                                            alt=""
                                            width={600}
                                            height={700}
                                            loading="lazy"
                                        />
                                    </div>
                                    <div className="col-md-7 col-lg-6 col-xxl-4">
                                        <h3>Unlock Unlimited Potential with HireBuddha</h3>
                                        <div className="offset-xxs">
                                            <p className="bigger">
                                                Why settle for conventional hiring practices when you can elevate your workforce with HireBuddha?
                                                By leveraging our platform, you gain access to a diverse pool of skilled professionals ready to tackle any task.
                                                Whether you're a startup, SME, or enterprise, HireBuddha empowers you to scale your operations, innovate faster, and stay ahead of the competition.
                                            </p>
                                        </div>
                                        <div className="offset-sm">
                                        <HireNow />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide section-lg">
                            <div className="container">
                                <div className="words">
                                    <span>H</span>
                                    <span>I</span>
                                    <span>R</span>
                                    <span>E</span>
                                    <span>&nbsp; </span>
                                    <span>&nbsp; </span>
                                    <span>B</span>
                                    <span>U</span>
                                    <span>D</span>
                                    <span>D</span>
                                    <span>H</span>
                                    <span>A</span>
                                </div>
                                <div className="row align-items-center justify-content-md-between flex-md-row-reverse">
                                    <div className="col-5 col-md-4 col-lg-4 col-xxl-7 text-center">
                                        <img
                                            className="animate slide delay-8"
                                            src={hero4}
                                            alt=""
                                            width={600}
                                            height={700}
                                            loading="lazy"
                                        />
                                    </div>
                                    <div className="col-md-7 col-lg-6 col-xxl-4">
                                        <h3>Meet Sarah, the Branding Consultant</h3>
                                        <div className="offset-xxs">
                                            <p className="bigger">
                                                Sarah is not your average marketing consultant – she's a Virtual Cognitive Resource (VCR) powered by HireBuddha.
                                                With years of industry experience and AI-enhanced capabilities, Sarah excels in crafting compelling brand strategies, designing impactful campaigns, and driving business growth.
                                                Say hello to your new secret weapon in marketing.
                                            </p>
                                        </div>
                                        <div className="offset-sm">
                                        <HireNow />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide section-lg">
                            <div className="container">
                                <div className="words">
                                    <span>H</span>
                                    <span>I</span>
                                    <span>R</span>
                                    <span>E</span>
                                    <span>&nbsp; </span>
                                    <span>&nbsp; </span>
                                    <span>B</span>
                                    <span>U</span>
                                    <span>D</span>
                                    <span>D</span>
                                    <span>H</span>
                                    <span>A</span>
                                </div>
                                <div className="row align-items-center justify-content-md-between flex-md-row-reverse">
                                    <div className="col-5 col-md-4 col-lg-4 col-xxl-7 text-center">
                                        <img
                                            className="animate slide delay-8"
                                            src={hero5}
                                            alt=""
                                            width={600}
                                            height={700}
                                            loading="lazy"
                                        />
                                    </div>
                                    <div className="col-md-7 col-lg-6 col-xxl-4">
                                        <h3>Introducing Alex, Your Data Wizard</h3>
                                        <div className="offset-xxs">
                                            <p className="bigger">
                                            Meet Alex, one of our exceptional Virtual Cognitive Resources (VCRs) specializing in data analytics and insights. 
                                            Equipped with advanced AI algorithms, Alex possesses the expertise to uncover valuable insights, optimize processes, and drive data-driven decision-making. 
                                            With Alex by your side, harness the power of data to propel your business forward.
                                            </p>
                                        </div>
                                        <div className="offset-sm">
                                        <HireNow />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide section-lg">
                            <div className="container">
                                <div className="words">
                                    <span>H</span>
                                    <span>I</span>
                                    <span>R</span>
                                    <span>E</span>
                                    <span>&nbsp; </span>
                                    <span>&nbsp; </span>
                                    <span>B</span>
                                    <span>U</span>
                                    <span>D</span>
                                    <span>D</span>
                                    <span>H</span>
                                    <span>A</span>
                                </div>
                                <div className="row align-items-center justify-content-md-between flex-md-row-reverse">
                                    <div className="col-5 col-md-4 col-lg-4 col-xxl-7 text-center">
                                        <img
                                            className="animate slide delay-8"
                                            src={hero6}
                                            alt=""
                                            width={600}
                                            height={700}
                                            loading="lazy"
                                        />
                                    </div>
                                    <div className="col-md-7 col-lg-6 col-xxl-4">
                                        <h3>Drive Efficiency, Reduce Costs with HireBuddha</h3>
                                        <div className="offset-xxs">
                                            <p className="bigger">
                                            Say goodbye to lengthy recruitment cycles and exorbitant costs. 
                                            With Hire Buddha, you can streamline your operations and maximize efficiency. 
                                            By leveraging AI-powered VCRs, tasks are completed faster, allowing you to save valuable time and resources. 
                                            Plus, our pay-as-you-go model ensures cost-effectiveness, with no upfront commitments. 
                                            Experience the future of workforce management with HireBuddha.
                                            </p>
                                        </div>
                                        <div className="offset-sm">
                                        <HireNow />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-pagination" data-pagination-ordered="" />
                </div>
            </section>
        </>
    );
}