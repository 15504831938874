export default function What() {
    return (
        <>
            <section className="section section-lg" id="what">
                <div className="container">
                    <div className="row row-30 align-items-center">
                        <div
                            className="col-xl-4 col-xxl-6 d-none d-xl-block"
                            data-animate='{"class":"fadeInUp"}'
                        >
                            <img
                                className="ms-xxl-2"
                                src="images/image-06-549x597.png"
                                alt=""
                                width={549}
                                height={597}
                                loading="lazy"
                            />
                        </div>
                        <div className="col-lg-10 col-xl-8 col-xxl-6 pb-xxl-2">
                            <div
                                className="d-sm-flex align-items-sm-center"
                                data-animate='{"class":"fadeInLeft"}'
                            >
                                <h2>Features</h2>
                                <p className="mt-sm-0 ms-sm-4 border-sm-left ps-sm-3">
                                    A few of HIRE-BUDDHA features...
                                </p>
                            </div>
                            <div className="offset-md">
                                <div className="swiper-modern-wrap">
                                    <div
                                        className="swiper-container swiper-modern"
                                        data-swiper='{"loop":false,"autoplay":false,"simulateTouch":false,"slidesPerView":1,"spaceBetween":30,"pagination":{"el":""},"scrollbar":{"draggable":true},"breakpoints":{"480":{"slidesPerView":2},"768":{"slidesPerView":3}}}'
                                    >
                                        <div className="swiper-wrapper">
                                            <div className="swiper-slide">
                                                {/* Blurb boxed*/}
                                                <article className="blurb blurb-boxed">
                                                    <div className="media media-xs flex-column">
                                                        <div className="media-left">
                                                            <div className="blurb-embed">
                                                                <span className="icon icon-round icon-md icon-secondary icon-style-3 mdi-cursor-default" />
                                                            </div>
                                                        </div>
                                                        <div className="media-body">
                                                            <div className="blurb-title h5">Virtual Cognitive Resources</div>
                                                         </div>   
                                                    </div>
                                                </article>
                                            </div>
                                            <div className="swiper-slide">
                                                {/* Blurb boxed*/}
                                                <article className="blurb blurb-boxed">
                                                    <div className="media media-sm flex-column">
                                                        <div className="media-left">
                                                            <div className="blurb-embed">
                                                                <span className="icon icon-round icon-md icon-primary icon-style-3 mdi-dice" />
                                                            </div>
                                                        </div>
                                                        <div className="media-body">
                                                            <div className="blurb-title h5">
                                                                24 X 7 X 365 Availability 
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                            <div className="swiper-slide">
                                                {/* Blurb boxed*/}
                                                <article className="blurb blurb-boxed">
                                                    <div className="media media-sm flex-column">
                                                        <div className="media-left">
                                                            <div className="blurb-embed">
                                                                <span className="icon icon-round icon-md icon-success icon-style-3 mdi-comment" />
                                                            </div>
                                                        </div>
                                                        <div className="media-body">
                                                            <div className="blurb-title h5">Cost Reduction = 90%</div>
                                                            
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                            <div className="swiper-slide">
                                                {/* Blurb boxed*/}
                                                <article className="blurb blurb-boxed">
                                                    <div className="media media-sm flex-column">
                                                        <div className="media-left">
                                                            <div className="blurb-embed">
                                                                <span className="icon icon-round icon-md icon-primary icon-style-3 mdi-reload" />
                                                            </div>
                                                        </div>
                                                        <div className="media-body">
                                                            <div className="blurb-title h5">Specialized Expertise</div>
                                                            
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                          
                                            <div className="swiper-slide">
                                                {/* Blurb boxed*/}
                                                <article className="blurb blurb-boxed">
                                                    <div className="media media-sm flex-column">
                                                        <div className="media-left">
                                                            <div className="blurb-embed">
                                                                <span className="icon icon-round icon-md icon-primary icon-style-3 mdi-dice" />
                                                            </div>
                                                        </div>
                                                        <div className="media-body">
                                                            <div className="blurb-title h5">
                                                                Smart Recommendations
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                            <div className="swiper-slide">
                                                {/* Blurb boxed*/}
                                                <article className="blurb blurb-boxed">
                                                    <div className="media media-sm flex-column">
                                                        <div className="media-left">
                                                            <div className="blurb-embed">
                                                                <span className="icon icon-round icon-md icon-success icon-style-3 mdi-comment" />
                                                            </div>
                                                        </div>
                                                        <div className="media-body">
                                                            <div className="blurb-title h5">Collaborative Workspaces</div>
                                                            
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                            <div className="swiper-slide">
                                                {/* Blurb boxed*/}
                                                <article className="blurb blurb-boxed">
                                                    <div className="media media-sm flex-column">
                                                        <div className="media-left">
                                                            <div className="blurb-embed">
                                                                <span className="icon icon-round icon-md icon-primary icon-style-3 mdi-reload" />
                                                            </div>
                                                        </div>
                                                        <div className="media-body">
                                                            <div className="blurb-title h5">Advanced Security and Compliance</div>
                                                            
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                            <div className="swiper-slide">
                                                {/* Blurb boxed*/}
                                                <article className="blurb blurb-boxed">
                                                    <div className="media media-sm flex-column">
                                                        <div className="media-left">
                                                            <div className="blurb-embed">
                                                                <span className="icon icon-round icon-md icon-secondary icon-style-3 mdi-cursor-default" />
                                                            </div>
                                                        </div>
                                                        <div className="media-body">
                                                            <div className="blurb-title h5">Scalability and Customization</div>
                                                            
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                            <div className="swiper-slide">
                                                {/* Blurb boxed*/}
                                                <article className="blurb blurb-boxed">
                                                    <div className="media media-sm flex-column">
                                                        <div className="media-left">
                                                            <div className="blurb-embed">
                                                                <span className="icon icon-round icon-md icon-primary icon-style-3 mdi-dice" />
                                                            </div>
                                                        </div>
                                                        <div className="media-body">
                                                            <div className="blurb-title h5">
                                                                Analytics and Reporting
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                            <div className="swiper-slide">
                                                {/* Blurb boxed*/}
                                                <article className="blurb blurb-boxed">
                                                    <div className="media media-sm flex-column">
                                                        <div className="media-left">
                                                            <div className="blurb-embed">
                                                                <span className="icon icon-round icon-md icon-success icon-style-3 mdi-comment" />
                                                            </div>
                                                        </div>
                                                        <div className="media-body">
                                                            <div className="blurb-title h5">Integration Capabilities</div>
                                                            
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                            
                                            <div className="swiper-slide">
                                                {/* Blurb boxed*/}
                                                <article className="blurb blurb-boxed">
                                                    <div className="media media-sm flex-column">
                                                        <div className="media-left">
                                                            <div className="blurb-embed">
                                                                <span className="icon icon-round icon-md icon-success icon-style-3 mdi-comment" />
                                                            </div>
                                                        </div>
                                                        <div className="media-body">
                                                            <div className="blurb-title h5">Continuous Improvement</div>
                                                           
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                            
                                        </div>
                                        <div className="swiper-scrollbar" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}